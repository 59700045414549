import React, { useState, useEffect } from "react";
import {
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  activateUser,
  changeUserPlanConfiguration,
  deactivateUser,
  getBrandList,
  getUserDetail,
  getUsers,
  markUserPaid,
} from "../../services/services";
import { Button, CloseButton } from "react-bootstrap";
import { BrandList, PaymentRange, User, UserDetail } from "../../types/types";
import { formatDateString } from "../../utils/dateUtils";
import { flagFromCountryCode } from "../../globalConstants";
import UserModal from "../UserModal/UserModal";

import styles from "./UsersTable.module.css";

const STATUSES = [
  { value: "", label: "Todos" },
  { value: "a", label: "Activos" },
  { value: "na", label: "Inactivos" },
  { value: "p", label: "Pagos" },
  { value: "np", label: "No Pagos" },
];

type UsersTableProps = {
  token: string;
};

const UsersTable = (props: UsersTableProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [userCount, setUserCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [displayingSearch, setDisplayingSearch] = useState(false);
  const [statusFilter, setStatusFilter] = useState(STATUSES[0].value);
  const [enableQuarterPayment, setEnableQuarterPayment] = useState(false);
  const [error, setError] = useState(false);

  const [showUserModal, setShowUserModal] = useState(false);
  const [userDetailId, setUserDetailId] = useState<string>("");
  const [userDetail, setUserDetail] = useState<UserDetail>();
  const [brands, setBrands] = useState<BrandList[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const today = new Date();
    setEnableQuarterPayment(today.getMonth() % 3 === 0);
  }, []);

  const queryClient = useQueryClient();
  const isFetching = useIsFetching();
  useQuery(
    ["users", page, searchFilter, statusFilter],
    () => getUsers(props.token, page, searchFilter, statusFilter),
    {
      onSuccess: (data) => {
        setUsers(data.users);
        setUserCount(data.userCount);
        setLastPage(data.pageCount);
      },
      onError: () => setError(true),
    }
  );
  useQuery(
    ["userDetail", userDetailId],
    () => getUserDetail(props.token, userDetailId),
    {
      enabled: Boolean(userDetailId),
      onSuccess: (data) => {
        setUserDetail(data);
      },
    }
  );
  useQuery(["brands"], () => getBrandList(props.token), {
    onSuccess: (data) => {
      setBrands(data);
    },
  });
  const paymentUserMutation = useMutation(
    (paymentMark: { id: string; range: PaymentRange }) =>
      markUserPaid(props.token, paymentMark.id, paymentMark.range),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
      onError: (error, variables) => {
        setError(true);
        setUsers((prevState) =>
          prevState.map((u) => {
            if (u.id === variables.id) return { ...u, processing: false };
            else return u;
          })
        );
      },
    }
  );
  const activateUserMutation = useMutation(
    (id: string) => activateUser(props.token, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        // if (data.firstTime === true) setActivatedUser(data);
      },
      onError: (error, variables) => {
        setError(true);
        setUsers((prevState) =>
          prevState.map((u) => {
            if (u.id === variables) return { ...u, processing: false };
            else return u;
          })
        );
      },
    }
  );
  const deactivateUserMutation = useMutation(
    (id: string) => deactivateUser(props.token, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
      onError: (error, variables) => {
        setError(true);
        setUsers((prevState) =>
          prevState.map((u) => {
            if (u.id === variables) return { ...u, processing: false };
            else return u;
          })
        );
      },
    }
  );
  const configurePlanMutation = useMutation(
    (planChange: { id: string; plan: string; brands: string[] }) =>
      changeUserPlanConfiguration(
        props.token,
        planChange.id,
        planChange.plan,
        planChange.brands
      ),
    {
      onMutate: () => {
        setIsProcessing(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries(["userDetail", userDetailId]);
      },
      onError: () => {
        setError(true);
      },
      onSettled: () => {
        setIsProcessing(false);
        setShowUserModal(false);
      },
    }
  );

  const firstPageHandler = () => {
    setPage(1);
  };

  const lastPageHandler = () => {
    setPage(lastPage);
  };

  const previousPageHandler = () => {
    setPage((prevState) => prevState - 1);
  };

  const nextPageHandler = () => {
    setPage((prevState) => prevState + 1);
  };

  const errorCloseHandler = () => {
    setError(false);
  };

  const searchInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const searchExecuteHandler = () => {
    setPage(1);
    setSearchFilter(searchInput);
    setDisplayingSearch(true);
  };

  const searchClearHandler = () => {
    setPage(1);
    setSearchInput("");
    setSearchFilter("");
    setDisplayingSearch(false);
  };

  const statusSetHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setPage(1);
    setStatusFilter(newValue);
  };

  const userPaymentNowClickedHandler = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const id = e.currentTarget.value;
    const user = users.find((u) => u.id === id);
    if (user) {
      setUsers((prevState) =>
        prevState.map((u) => {
          if (u.id === id) return { ...u, processing: true };
          else return u;
        })
      );
      paymentUserMutation.mutate({ id, range: PaymentRange.MONTH });
    }
  };

  const userPaymentQuarterClickedHandler = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const id = e.currentTarget.value;
    const user = users.find((u) => u.id === id);
    if (user) {
      setUsers((prevState) =>
        prevState.map((u) => {
          if (u.id === id) return { ...u, processing: true };
          else return u;
        })
      );
      paymentUserMutation.mutate({ id, range: PaymentRange.QUARTER });
    }
  };

  const userPaymentYearClickedHandler = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const id = e.currentTarget.value;
    const user = users.find((u) => u.id === id);
    if (user) {
      setUsers((prevState) =>
        prevState.map((u) => {
          if (u.id === id) return { ...u, processing: true };
          else return u;
        })
      );
      paymentUserMutation.mutate({ id, range: PaymentRange.YEAR });
    }
  };

  const userStatusChangedHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.value;
    const user = users.find((u) => u.id === id);
    if (user) {
      setUsers((prevState) =>
        prevState.map((u) => {
          if (u.id === id) return { ...u, processing: true };
          else return u;
        })
      );
      if (user.active) deactivateUserMutation.mutate(id);
      else activateUserMutation.mutate(id);
    }
  };

  const userPlanChangedHandler = async (
    id: string,
    plan: string,
    brands: string[]
  ) => {
    configurePlanMutation.mutate({ id, plan, brands });
  };

  const userDetailClickedHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.value;
    if (userDetail && userDetailId === id) {
      setShowUserModal(true);
      return;
    }

    const user = users.find((u) => u.id === id);
    if (user) {
      setUserDetail(undefined);
      setUserDetailId(id);
      setShowUserModal(true);
    }
  };

  const userDetailClosedHandler = () => {
    setShowUserModal(false);
  };

  return (
    <>
      <UserModal
        data={userDetail}
        show={showUserModal}
        brands={brands}
        processing={isProcessing}
        onPlanChange={userPlanChangedHandler}
        onClose={userDetailClosedHandler}
      />

      <div className="mb-5">
        <div className={styles.userTableDiv}>
          {error && (
            <div
              className="alert alert-danger w-100 d-flex flex-row align-items-center justify-content-between"
              role="alert"
            >
              <div className="d-flex flex-row align-items-center">
                <i className="bi-exclamation-triangle-fill fs-4 me-2"></i>
                <span>
                  La operación no pudo completarse debido a un error inesperado.
                </span>
              </div>
              <CloseButton aria-label="Close" onClick={errorCloseHandler} />
            </div>
          )}
          <div className={`${styles.userTableToolbar} mb-2`}>
            {!Boolean(isFetching) ? (
              <div className="d-flex flex-row align-items-center">
                <i className="bi-person-fill fs-5 me-1"></i>
                <span>Resultados: {userCount}</span>
              </div>
            ) : (
              <span>
                <div className="d-flex flex-row align-items-center">
                  <span className="spinner-border spinner-border-sm text-primary me-2"></span>
                  <span>Cargando...</span>
                </div>
              </span>
            )}
            <div className="d-flex flex-row">
              <div className="d-flex flex-row me-3">
                {displayingSearch && (
                  <Button
                    variant="light"
                    size="sm"
                    className="me-3"
                    onClick={searchClearHandler}
                  >
                    Limpiar
                  </Button>
                )}
                <div className="input-group search-field">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre o código"
                    maxLength={20}
                    disabled={Boolean(isFetching)}
                    value={searchInput}
                    onChange={searchInputChangeHandler}
                    //keyUpEnter??
                  />
                  <Button
                    variant="outline-secondary"
                    disabled={!searchInput || Boolean(isFetching)}
                    onClick={searchExecuteHandler}
                  >
                    <i className="bi-search px-2"></i>
                  </Button>
                </div>
              </div>
              <div className="dropdown w-20">
                <select
                  className="form-select"
                  disabled={Boolean(isFetching)}
                  value={statusFilter}
                  onChange={statusSetHandler}
                >
                  {STATUSES.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <table className={`table table-striped ${styles.userTable}`}>
            <thead>
              <tr className="table-dark">
                <th scope="col">#</th>
                <th scope="col">Usuario</th>
                <th scope="col">Marcas</th>
                <th scope="col">Plan</th>
                <th scope="col">Ingreso</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>

            <tbody>
              {users &&
                users.map((user) => (
                  <tr
                    key={user.code}
                    className={!user.active ? styles.userTableInactive : ""}
                  >
                    <td>{user.code}</td>
                    <td>
                      {`${user.name} ${
                        user.intl ? flagFromCountryCode(user.country) : ""
                      }`}
                    </td>
                    <td>{user.brands}</td>
                    <td>{user.plan}</td>
                    <td>{formatDateString(user.joinDate)}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        {user.paid ? (
                          <span className="flex-grow-1 text-center">
                            ✓ Pagado
                          </span>
                        ) : !user.intl ? (
                          <>
                            <Button
                              variant="info"
                              size="sm"
                              className={`${styles.userTableButton} ${styles.userTableButtonSmall}`}
                              value={user.id}
                              disabled={!user.init || user.processing}
                              onClick={userPaymentNowClickedHandler}
                            >
                              {!user.processing ? (
                                <span>$ Mes</span>
                              ) : (
                                <span>
                                  <span className="spinner-border spinner-border-sm"></span>
                                </span>
                              )}
                            </Button>
                            <Button
                              variant="info"
                              size="sm"
                              className={`${styles.userTableButton} ${styles.userTableButtonSmall}`}
                              value={user.id}
                              disabled={
                                !user.init ||
                                user.processing ||
                                !enableQuarterPayment
                              }
                              onClick={userPaymentQuarterClickedHandler}
                            >
                              {!user.processing ? (
                                <span>$ Trim</span>
                              ) : (
                                <span>
                                  <span className="spinner-border spinner-border-sm"></span>
                                </span>
                              )}
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="info"
                            size="sm"
                            className={`${styles.userTableButton} ${styles.userTableButtonSmall}`}
                            value={user.id}
                            disabled={!user.init || user.processing}
                            onClick={userPaymentYearClickedHandler}
                          >
                            {!user.processing ? (
                              <span>U$S Año</span>
                            ) : (
                              <span>
                                <span className="spinner-border spinner-border-sm"></span>
                              </span>
                            )}
                          </Button>
                        )}
                        <Button
                          variant={
                            user.init
                              ? !user.active
                                ? "primary"
                                : "danger"
                              : "success"
                          }
                          size="sm"
                          className={styles.userTableButton}
                          value={user.id}
                          disabled={user.processing}
                          onClick={userStatusChangedHandler}
                        >
                          {!user.processing ? (
                            <span>
                              {user.init
                                ? user.active
                                  ? "Desactivar"
                                  : "Activar"
                                : "Iniciar"}
                            </span>
                          ) : (
                            <span>
                              <span className="spinner-border spinner-border-sm"></span>
                            </span>
                          )}
                        </Button>
                        <Button
                          variant="light"
                          size="sm"
                          value={user.id}
                          onClick={userDetailClickedHandler}
                        >
                          <i className="bi-info-circle"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className={styles.paginator}>
            <div className={styles.paginatorButtons}>
              <Button
                variant="dark"
                disabled={page === 1 || Boolean(isFetching)}
                onClick={firstPageHandler}
              >
                <i className="bi-chevron-bar-left"></i>
              </Button>
              <Button
                variant="dark"
                className={styles.paginatorButtonLong}
                disabled={page === 1 || Boolean(isFetching)}
                onClick={previousPageHandler}
              >
                <i className="bi-chevron-left"></i>
              </Button>
              <span className="paginator-label">
                Página {page} / {lastPage}
              </span>
              <Button
                variant="dark"
                className={styles.paginatorButtonLong}
                disabled={page === lastPage || Boolean(isFetching)}
                onClick={nextPageHandler}
              >
                <i className="bi-chevron-right"></i>
              </Button>
              <Button
                variant="dark"
                disabled={page === lastPage || Boolean(isFetching)}
                onClick={lastPageHandler}
              >
                <i className="bi-chevron-bar-right"></i>
              </Button>
            </div>
            {Boolean(isFetching) && (
              <div className="spinner-border text-primary paginator-spinner"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersTable;
